/* eslint-disable react/style-prop-object */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable global-require */
/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
// components

import '../style/tailwind.css';
import '../style/landing.css';
import '../style/whatsapp.css';
import '../style/responsive.css';

import { Button } from 'bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faKey, faBed, faScrewdriverWrench, faCheck,
  faHouse, faDollarSign, faCalendarDays,
  faWallet, faSackDollar, faFile, faFileInvoice, faCheckCircle, faBrush, faBroom, faQuoteLeft, // faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useMsal } from '@azure/msal-react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import 'typeface-roboto';
import airbnb from '../media/platforms/logo-airbnb-bw.png';
import booking from '../media/platforms/logo-booking-bw.png';
import expedia from '../media/platforms/logo-expedia-bw.png';
import hoteis from '../media/platforms/logo-hoteis-bw.com.png';

import janete from '../media/employees/janete.jpeg';
import cida from '../media/employees/cida.jpeg';
import djalma from '../media/employees/djalma.jpeg';

import bg1 from '../media/bg1.jpg';
import bg2 from '../media/bg2.jpg';

import girl from '../media/girl.png';

import Listing from '../components/Listing';
import listings from '../listings';
import reviews from '../reviews';

import Service from '../components/Service';
import ListingsModal from '../components/ListingsModal';

export default function Landing() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  return (
    <>
      <Navbar transparent />
      <main>
        <div className="cover">
          <a
            href="https://api.whatsapp.com/send?phone=5511987781650"
            target="_blank"
            rel="noopener noreferrer"
            className="whatsapp-button"
          >
            <FontAwesomeIcon icon={faWhatsapp} />
          </a>
          <div
            className="bg"
            // style={{
            //   backgroundImage:
            //   "url('https://www.iguest.com.br/image/d235/lancamento-studio-moderno-no-centro-5f7efb54190d0fd92ae57e24?ver=f668&width=786')",
            // }}
          >
            <img
              alt="..."
              src={bg1}
              className=""
            />
            <span
              className="whiteOverlay"
            />
          </div>
          <div style={{ position: 'relative' }} className="container">
            <div className="">
              <div className="lg6/12 padx">
                <div>
                  {/* <img
                    alt="logo"
                    src={logo}
                    style={{ marginLeft: '-0.5rem' }}
                    // className="shadow-lg rounded-full mx-auto max-w-120-px"
                  /> */}
                  <h1 className="title">Sua casa em</h1>
                  <h1 className="title">São Paulo</h1>
                  <p className="subtitle">
                    Apartamentos confortáveis, decorados e na melhor localização da cidade.
                  </p>
                  <button
                    style={{ marginTop: '1rem' }}
                    className="text-black active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    <a
                      href="https://seustudio.rsvar.me/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="inline-block ml-2">RESERVE AGORA</span>
                    </a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="curve">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="lightbg"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>

        <section className="grayArea" id="servicos">
          <div className="container">
            <div className="div-flex">
              <div className="md4/12 sm12/12 padx">
                <Service
                  title="Relacionamento com hóspedes"
                  icon={faUser}
                  description="Com nosso serviço de check-in/check-out, cuidamos de tudo,
                      desde a comunicação prévia com os hóspedes, até a recepção na propriedade e a entrega das chaves."
                  tags={['Check-In', 'Check-Out', 'Controle de avaliações', 'Atendimento Ágil', 'Profissionais capacitados']}
                />
              </div>

              <div className="md4/12 sm12/12 padx">
                <Service
                  title="Limpeza"
                  icon={faBroom}
                  description="Nos responsabilizamos em realizar uma limpeza minuciosa e profissional,
                  garantindo que os hóspedes tenham uma estadia agradável e confortável."
                  tags={['Higienização', 'Limpeza', 'Serviços de Lavanderia', 'Amenities']}
                />
              </div>

              <div className="md4/12 sm12/12 padx">
                <Service
                  title="Gerenciamento de anúncios"
                  icon={faCalendarDays}
                  description="Oferecemos um serviço completo de gerenciamento de anúncios,
                  incluindo fotos profissionais e sincronização de calendários para evitar reservas duplas e conflitos de disponibilidade."
                  tags={['Fotografia', 'Gestão dinâmica de preços', 'Sincronização de calendários']}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="relative padTop platforms" id="listings">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>

          <h3 className="title padx padTop">
            Sua propriedade nos melhores sites de reserva
          </h3>

          <div className="container">
            <div className="container">
              <div className="div-flex" style={{ marginTop: '2rem' }}>
                <div className="md3/12 padxx pady center">
                  <img src={airbnb} alt="airbnb" />
                </div>
                <div className="md3/12 padxx pady center">
                  <img src={booking} alt="airbnb" />
                </div>
                <div className="md3/12 padxx pady center">
                  <img src={expedia} alt="airbnb" />
                </div>
                <div className="md3/12 padxx pady center">
                  <img src={hoteis} alt="airbnb" />
                </div>
              </div>
            </div>
          </div>

        </section>

        <section className="relative listings">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
          {/*
          <h3 className="title padx padTop">
            Alguns de nossos anúncios
          </h3> */}

          <div className="container listings">
            <div className="container">
              <div className="div-flex" style={{ marginTop: '2rem' }}>
                <div className="md4/12 sm12/12 padx">
                  <Listing
                    data={listings[0]}
                  />
                </div>
                <div className="md4/12 sm12/12 padx">
                  <Listing
                    data={listings[1]}
                  />
                </div>
                <div className="md4/12 sm12/12 padx">
                  <Listing
                    data={listings[2]}
                  />
                </div>
              </div>
              <div className="text-center mt-6 padyy">
                {/* <button
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Ver todos nossos anúncios
                </button> */}
                <ListingsModal />
              </div>
            </div>
          </div>
          {/* <div className="container listings">
            <div className="container">
              <div className="div-flex" style={{ marginTop: '2rem' }}>
                <div className="md4/12 padx pady center">
                  <Listing
                    airbnbTitle={listings[0].airbnbTitle}
                    airbnbLocation={listings[0].airbnbLocation}
                    airbnbImage={listings[0].airbnbImage}
                    airbnbBedCount={listings[0].airbnbBedCount}
                  />
                </div>
                <div className="md4/12 padx pady center">
                  <Listing
                    airbnbTitle={listings[1].airbnbTitle}
                    airbnbLocation={listings[1].airbnbLocation}
                    airbnbImage={listings[1].airbnbImage}
                    airbnbBedCount={listings[1].airbnbBedCount}
                  />
                </div>
                <div className="md4/12 padx pady center">
                  <Listing
                    airbnbTitle={listings[2].airbnbTitle}
                    airbnbLocation={listings[2].airbnbLocation}
                    airbnbImage={listings[2].airbnbImage}
                    airbnbBedCount={listings[2].airbnbBedCount}
                  />
                </div>

                <Link
                  className="description w-full text-center text-sm leading-relaxed inline-block mr-4 padBottom"
                  to="/"
                >
                  Veja todos nossos anúncios
                </Link>
              </div>
            </div>
          </div> */}

        </section>

        <div className="subcover" style={{ minHeight: '80vh' }}>
          <div
            className="bg"
            style={{
              // backgroundImage: { bg2 },
              // "url(../media/ap1.jpeg')",
              // "url('http://cdn.home-designing.com/wp-content/uploads/2018/12/luxury-apartment-kitchen.jpg')",
              // "url('https://a0.muscache.com/im/pictures/eaa86b44-dae2-4464-8e18-c0ca14db65cd.jpg?im_w=1200')",
            }}
          >
            <img
              alt="..."
              src={bg2}
              className=""
            />
            <span
              className="blackOverlay"
            />
          </div>
          <div className="container">
            <h1 className="title padxx">Rentabilize sua propriedade com o aluguel por temporada e deixe-nos cuidar de tudo para você!</h1>
          </div>
        </div>

        {/* <section className="padx pady employees">
          <div className="container">
            <div className="div-flex justify-center text-center padyy">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="title">Conheça a nossa equipe</h2>
                <p className="description">
                  Nossa equipe é experiente e especializada na gestão completa de propriedades no Airbnb.
                  Confie em nós para cuidar de tudo com dedicação e garantir a tranquilidade na administração do seu imóvel de aluguel por temporada.
                </p>
              </div>
            </div>
            <div className="div-flex">
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={employee1}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="employee">
                    <h5 className="name">Stephanie</h5>
                    <p className="role">
                      Relacionamento com Hóspedes
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={employee2}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="employee">
                    <h5 className="name">Bianca</h5>
                    <p className="role">
                      Limpeza
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={employee3}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="employee">
                    <h5 className="name">Jonathan</h5>
                    <p className="role">
                      Manutenção
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={employee4}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="employee">
                    <h5 className="name">Camila</h5>
                    <p className="role">
                      Financeiro
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="pb-20 bg-blueGray-200">
          <div className="container mx-auto py-16">
            <div className="flex flex-wrap items-center">
              <div className="w-full md:w-6/12 px-4 mr-auto ml-auto">

                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Conte conosco para um serviço completo e confiável
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                  Confie em nosso relatório mensal para uma visão abrangente e detalhada do desempenho da sua propriedade no Airbnb
                </p>

              </div>

              <div className="w-full md:w-6/12 px-4">
                <div>
                  <img
                    alt="..."
                    src={girl}
                    className=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="padx pady employees">
          <div className="container">
            <div className="div-flex justify-center text-center padyy">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="title">Leia o que nossos clientes dizem</h2>
              </div>
            </div>
            <div className="div-flex">
              <div className="w-full md:w-12/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={janete}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="employee">
                    <h5 className="name">{reviews[0].name}</h5>
                    <FontAwesomeIcon icon={faQuoteLeft} />
                    <p className="testimony">
                      {reviews[0].text}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-12/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={cida}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="employee">
                    <h5 className="name">{reviews[1].name}</h5>
                    <FontAwesomeIcon icon={faQuoteLeft} />
                    <p className="testimony">
                      {reviews[1].text}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-12/12 lg:w-4/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={djalma}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="employee">
                    <h5 className="name">{reviews[2].name}</h5>
                    <FontAwesomeIcon icon={faQuoteLeft} />
                    <p className="testimony">
                      {reviews[2].text}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src={employee4}
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="employee">
                    <h5 className="name">Camila</h5>
                    <p className="role">
                      Financeiro
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="pb-20 relative block darkBlue" id="transparency">
          <div
            className="reverse-curve"
            style={{ transform: 'translateZ(0)' }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-800 fill-current"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>

          <div className="container transparency">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="title" style={{ color: 'white' }}>
                  Valorizamos a transparência em nossos serviços
                </h2>
                <p className="description-dark">
                  Todos os serviços prestados são totalmente transparentes, sem taxas ocultas ou surpresas desagradáveis.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-4/12 px-4 text-center pady">
                <div className="bubble">
                  <FontAwesomeIcon icon={faSackDollar} />
                </div>
                <h5 className="subtitle" style={{ color: 'white', marginTop: '1rem' }}>
                  Dinheiro na conta
                </h5>
                <p className="description-dark">
                  Você recebe todo o dinheiro arrecadado com as reservas diretamente para sua conta bancária.
                  Não cobramos taxas extras ou comissões sobre essas transações, garantindo que você receba o valor total de suas reservas.
                </p>
              </div>
              <div className="w-full lg:w-4/12 px-4 text-center pady">
                <div className="bubble">
                  <FontAwesomeIcon icon={faFileInvoice} />
                </div>
                <h5 className="subtitle" style={{ color: 'white', marginTop: '1rem' }}>
                  Recebimento de relatorio
                </h5>
                <p className="description-dark">
                  Fornecemos um relatório mensal detalhado de todos os serviços prestados,
                  incluindo o valor das reservas, os custos de limpeza e taxa de administração,
                  bem como quaisquer outras despesas relevantes.
                </p>
              </div>
              <div className="w-full lg:w-4/12 px-4 text-center pady">
                <div className="bubble">
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                <h5 className="subtitle" style={{ color: 'white', marginTop: '1rem' }}>
                  Pagamento
                </h5>
                <p className="description-dark">
                  Você tem a certeza de que está pagando somente pelos serviços realizados.
                  Acreditamos que essa abordagem é justa e demonstra o nosso compromisso com a satisfação dos nossos clientes.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="relative block py-24 lg:pt-0 bg-blueGray-800 pady">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200">
                  <div className="flex-auto p-5 lg:p-10">
                    <h4 className="text-2xl font-semibold">
                      Interessado nos nossos serviços?
                    </h4>
                    <p className="leading-relaxed mt-1 mb-4 text-blueGray-500">
                      Entre em contato conosco.
                    </p>
                    <div className="relative w-full mb-3 mt-8">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="full-name"
                      >
                        Nome Completo
                      </label>
                      <input
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Nome Completo"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        Menssagem
                      </label>
                      <textarea
                        rows="4"
                        cols="80"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Descreva a sua propriedade..."
                      />
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <Footer />
      </main>
      {/* <Footer /> */}
    </>
  );
}
